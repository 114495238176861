export default function (cnpj) {
  return cnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    function (regex, argumento1, argumento2, argumento3, argumento4,argumento5) {
      return (
        argumento1 + '.' + argumento2 + '.' + argumento3 + '/' + argumento4 + '-' + argumento5
      );
    },
  );
}
