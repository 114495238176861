import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CodeBar from '../../images/barcode.png';
import Copybar from '../../images/copy.png';

import { format, differenceInDays } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import toMoney from '../../functions/toMoney';
import Summary from '../../components/Summary';

function Table({ tab, loading, contract, onChange, onSelected }) {
  const [selected, setSelected] = useState([]);
  const [allCharges, setAllCharges] = useState([]);
  const [filter, setFilter] = useState({
    overdue: false,
    open: false,
  });
  const [show, setShow] = useState(false);
  const [showCopyToClipboard, setShowCopyToClipboard] = useState(false);
  const [codeBar, setCodeBar] = useState('');
  const [totalChargesOpen, setTotalChargesOpen] = useState(0);
  const [totalChargesOverdue, setTotalChargesOverdue] = useState(0);
  const [chargesPagination, setChargesPagination] = useState([]);
  const [suspended, setSuspend] = useState(false);
  const [page, setPage] = useState(1);
  const [errorSelect, setErrorSelect] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowCopyToClipboard(false);
  };

  const handleShow = code => {
    setCodeBar(code);
    setShow(true);
  };

  const mountPagination = (data, max) => {
    const chargesPagination = [[]];
    let index = 0;

    for (let i = 0; i < data.length; i++) {
      if (chargesPagination[index] === undefined) {
        chargesPagination[index] = [];
      }

      chargesPagination[index].push(data[i]);

      if ((i + 1) % max === 0) {
        index = index + 1;
      }
    }

    return chargesPagination;
  };

  const handleDataFromBillsOpen = data => {
    return data
      .filter(value => value.forma_pagamento.trim() !== 'CC')
      .map(value => {
        const date = format(new Date(value.vencto), 'dd/MM/yyyy');
        const status =
          differenceInDays(new Date(), new Date(value.vencto)) > 1
            ? 'Em atraso'
            : 'Em aberto';
        const price = toMoney(value.valor_titulo);
        return {
          check: false,
          contrato: contract.codigo,
          chave: value.chave,
          date,
          status,
          price,
          codeBar: value?.boleto?.linha_digitavel || '',
        };
      });
  };

  const sortCharges = data => {
    return data.sort((a,b) => {
      return new Date(a.date.split('/')[2], parseInt(a.date.split('/')[1]) -1, a.date.split('/')[0]) - new Date(b.date.split('/')[2], parseInt(b.date.split('/')[1]) -1, b.date.split('/')[0])
    });
  }

  const countCharges = totalCharges => {
    if (!totalCharges) {
      setTotalChargesOpen(0);
      return setTotalChargesOverdue(0);
    }
    setTotalChargesOpen(totalCharges.filter(c => c.status === 'Em aberto').length);
    setTotalChargesOverdue(totalCharges.filter(c => c.status === 'Em atraso').length);
  };

  const mountLines = () => {
    if (contract && contract.titulos_abertos.length > 0) {
      const billsOpen = handleDataFromBillsOpen(contract.titulos_abertos);
      if (!!billsOpen.length) {
        countCharges(billsOpen);
        const chargesSort = sortCharges(billsOpen);
        return setAllCharges(chargesSort);
      }
      countCharges(false);
      return setAllCharges([]);
    }
    countCharges(false);
    return setAllCharges([]);
  }

  const handleFilter = type => {
    if (type === 'open') setFilter({ open: !filter.open, overdue: false });
    if (type === 'overdue')
      setFilter({ overdue: !filter.overdue, open: false });
  };

  const handlePagination = direction => {
    if (direction === 'left' && page > 0) setPage(page - 1);
    if (direction === 'right' && page < chargesPagination.length)
      setPage(page + 1);
    if (typeof direction === 'number') setPage(direction);
  };

  const renderTableHeader = () => (
    <div>
      <div className="titulo-elementos d-flex bd-highlight">
        <div className="d-flex flex-row">
          <div className="flex-grow-1 bd-highlight">
            <h6 className="titulo-card">Últimos lançamentos</h6>
          </div>
        </div>
        <div className="filtrar-por-mobile d-flex ms-auto">
          <div className="filtrar-por bd-highlight">Filtrar por:</div>
          <div
            className={
              filter.open
                ? 'btn-filtro-card-active btn-filtro-card bd-highlight'
                : 'btn-filtro-card bd-highlight'
            }
            onClick={() => handleFilter('open')}
          >
            Em aberto
            <span className="cor-em-aberto-numero">
              {` ${totalChargesOpen}`}
            </span>
          </div>
          <div
            className={
              filter.overdue
                ? 'btn-filtro-card-active btn-filtro-card bd-highlight'
                : 'btn-filtro-card bd-highlight'
            }
            onClick={() => handleFilter('overdue')}
          >
            Em atraso
            <span className="cor-em-atraso-numero">
              {` ${totalChargesOverdue}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const handleCheck = index => {
    // adiciona o check true
    const arrAll = chargesPagination;
    const arr = chargesPagination[page - 1];
    arr[index].check = !arr[index].check;
    arrAll.splice(page - 1, 1);
    arrAll.splice(page - 1, 0, arr);
    setChargesPagination(arrAll);

    // adiciona os selecionados
    const arrSelected = [];
    for (let i = 0; i < arrAll.length; i++) {
      chargesPagination[i].forEach(c => {
        if (c.check) {
          arrSelected.push(c);
        }
      });
    }
    setSelected(arrSelected);
    onSelected(arrSelected);

    // verifica se esta na ordem de vencimento
    arrSelected.sort((a, b) => {
      const c = a.date.split('/');
      const d = b.date.split('/');
      const dateA = new Date(c[2], c[1], c[0]);
      const dateB = new Date(d[2], d[1], d[0]);

      if (dateA > dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });

    arrSelected.map((item, index) => {
      if (allCharges[index] === item) {
        return setErrorSelect(false);
      }
      return setErrorSelect(true);
    });
  };

  const renderTable = () => {
    if (loading) {
      return <Skeleton count={6} height={62} />;
    }
    if (chargesPagination.length === 0 || allCharges.length === 0) {
      return (
        <div className="alert alert-warning" role="alert">
          Você não possui faturas abertas para o contrato selecionado!
        </div>
      );
    }

    return chargesPagination[page - 1].map((c, i) => (
      <label htmlFor={`check-${i}`} key={c.chave}>
        <div className="descricao-tabela d-flex bd-highlight" style={{ background: c.check ? '#F6F1FF' : '' }}>
          <div style={{ maxWidth: '8%' }} className="flex-fill bd-highlight">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`check-${i}`}
              name={`check-${i}`}
              defaultChecked={c.check}
              onClick={() => handleCheck(i)}
            />
          </div>

          <div style={{ maxWidth: '33%' }} className="flex-fill bd-highlight">
            {c.date}
          </div>
          <div style={{ maxWidth: '27%' }} className="flex-fill bd-highlight">
            R$ {c.price}
          </div>
          <div
            style={{ maxWidth: '22%' }}
            className="flex-fill bd-highlight font-size-12"
          >
            <span
              className={
                c.status === 'Em aberto'
                  ? 'badge badge-soft-success'
                  : 'badge badge-soft-danger'
              }
            >
              {c.status}
            </span>
          </div>
          {c.codeBar && (
            <div style={{ maxWidth: '10%' }} className="flex-fill bd-highlight">
              <Button
                variant="primary"
                style={{
                  backgroundImage: `url(${CodeBar})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: '#ffff',
                  backgroundSize: 'contain',
                  width: 62,
                  height: 36,
                  borderColor: '#fff',
                }}
                onClick={() => handleShow(c.codeBar)} />
            </div>
          )}
        </div>
      </label>
    ));
  };

  const renderTablePagination = () => (
    <div className="parent">
      <div className="left"></div>
      <div className="right">
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <i
            className="seta-paginacao align-self-center ri-arrow-left-s-line"
            onClick={() => { page !== 1 && handlePagination('left')}}
          ></i>
          {chargesPagination.map((pages, key) => (
            <div
              className="paginacao-lista"
              style={{
                marginRight: chargesPagination.length -1 === key ? 0 : '0.2rem',
                backgroundColor: page === key+1 ? '#8253c6' : '',
                color: page === key+1 ? '#fff' : '',
                fontSize: 14,
                padding: 0,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '2.4rem',
                width: '2.4rem',
                cursor: 'pointer',
              }}
              onClick={() => handlePagination(key+1)}
            >
              {key+1}
            </div>
          ))}
          <i
            className="seta-paginacao align-self-center ri-arrow-right-s-line"
            onClick={() => handlePagination('right')}
          ></i>
        </div>
      </div>
      <div className="align-self-center">
        <div className="texto-contagem-rodape-lista">
          Exibindo {chargesPagination.length ? chargesPagination[page -1].length : 0} lançamentos
        </div>
      </div>
    </div>
  );

  const renderSelected = () => (
    <div className="active-pagamento cor-global-secundaria">
      <div className="titulo-tabela pagamento-selecionado-tabela d-flex bd-highlight">
        {errorSelect ? (
          <div
            style={{ maxWidth: '60%' }}
            className="align-self-center cor-global-primaria selecionados-lista flex-fill bd-highlight"
          >
            As parcelas devem ser selecionadas por ordem de vencimento
          </div>
        ) : (
          <>
            <div
              style={{ maxWidth: '60%' }}
              className="align-self-center cor-global-primaria selecionados-lista flex-fill bd-highlight"
            >
              {selected.length} Selecionados
            </div>
            <div style={{ maxWidth: '40%' }} className="ms-auto bd-highlight">
              <button
                className="btn-pagar-agora  btn btn-primary btn-lg "
                type="submit"
                onClick={onChange}
              >
                Pagar agora
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const renderTableTitle = () => (
    <div className="titulo-tabela d-flex bd-highlight">
      <div style={{ maxWidth: '8%' }} className="flex-fill bd-highlight"></div>
      <div style={{ maxWidth: '33%' }} className="flex-fill bd-highlight">
        Boleto
      </div>
      <div style={{ maxWidth: '27%' }} className="flex-fill bd-highlight">
        Valor
      </div>
      <div style={{ maxWidth: '22%' }} className="flex-fill bd-highlight">
        Situação
      </div>
    </div>
  );

  useEffect(() => {
    if (contract && contract.status === 'S') setSuspend(true);
    else setSuspend(false);
  }, [contract, tab]);

  useEffect(() => {
    const data = mountPagination(allCharges, 6);
    setChargesPagination(data);
  }, [allCharges, tab]);

  useEffect(() => {
    if (!filter.open && !filter.overdue) {
      const data = mountPagination(allCharges, 6);
      setChargesPagination(data);
    } else {
      const all = allCharges.filter(
        c => c.status === (filter.open ? 'Em aberto' : 'Em atraso'),
      );
      const data = mountPagination(all, 6);
      setPage(1);
      setChargesPagination(data);
    }
  }, [filter, tab]);

  useEffect(() => {
    setSelected([]);
    onSelected([]);
    setPage(1);
  }, [tab]);

  useEffect(() => {
    mountLines();
  }, [contract, tab]);

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="my-1 p-3 bg-body rounded shadow-sm">
          {selected.length === 0 && renderTableHeader()}
          <div className="">
            <div className="">
              {!suspended && selected.length > 0 && renderSelected()}
              {renderTableTitle()}
              {suspended ? (
                <div className="alert alert-warning" role="alert">
                  Contrato Suspenso
                  <br />
                  Caro cliente, identificamos que o senhor(a) está com um débito
                  acima de 3 mensalidades, por esse motivo, o seu plano está
                  suspenso. Solicitamos que entre em contato por telefone ou se
                  dirija até uma agência de atendimento para colocar o seu plano
                  em dia.
                  <br />
                  Caso esteja com as mensalidades em dia, por favor,
                  desconsidere essa mensagem e entre em contato conosco.
                  Obrigado!
                </div>
              ) : (
                renderTable()
              )}
              {!suspended && renderTablePagination()}
            </div>
          </div>
        </div>
      </div>
      <Summary selected={selected} />
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              fontSize: '14px',
              color: '#9b9b9b',
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            Pagar boleto
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          <div
            style={{
              fontSize: '16px',
              fontWeight: '600',
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '5px',
              background: '#ebebeb',
              marginLeft: '15px',
              marginRight: '15px',
              borderRadius: '10px',
              paddingTop: '30px',
              paddingBottom: '30px',
              wordBreak: 'break-all',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {codeBar || 'Não foi possível encontrar o código de barra'}
          </div>
          {showCopyToClipboard && (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                color: '#05a305',
                textAlign: 'center',
                margin: '0 auto',
                marginTop: '10px'
              }}
            >
              Código copiado!
            </span>
          )}
        </Modal.Body> */}
        <Modal.Footer style={{margin: '0 auto', border: 'none' }}>
          <CopyToClipboard text={codeBar.trim()} onCopy={() => setShowCopyToClipboard(true)}>
            <Button
             style={{
              background: '#ebebeb',
              color: '#333',
             }}>
              Clique para copiar o código de barras <Button
                variant="primary"
                style={{
                  backgroundImage: `url(${Copybar})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: '#ebebeb',
                  backgroundSize: 'contain',
                  width: 20,
                  height: 20,
                  borderColor: '#ebebeb',
                  marginTop: '-3px',
                }}/>
            <div
            style={{
              fontSize: '16px',
              fontWeight: '600',
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '5px',
              background: '#ebebeb',
              marginLeft: '15px',
              marginRight: '15px',
              borderRadius: '10px',
              paddingBottom: '10px',
              wordBreak: 'break-all',
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            {codeBar || 'Não foi possível encontrar o código de barra'}
          </div>
          {showCopyToClipboard && (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                color: '#05a305',
                textAlign: 'center',
                margin: '0 auto',
                marginTop: '10px'
              }}
            >
              Código copiado!
            </span>
          )}
            </Button>
          </CopyToClipboard>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Table;
