import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import LayoutContext from '../../../contexts/layout';
export default function AuthLayout({ children }) {
  const { loginBanner } = useContext(LayoutContext);

  return (
    <>
	    <div className="home-btn d-none d-sm-block">
        <a href="/"><i className="mdi mdi-home-variant h2 text-white"></i></a>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="authentication-bg" style={{backgroundImage: `url(${loginBanner})`}}>
              <div className="bg-overlay"></div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
