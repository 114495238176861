import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cgc_cliente: Yup.string().required('Campo obrigatório'),
  password: Yup.string()
    .min(6, 'A senha deve ter no minimo 6 caracteres')
    .required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Campo obrigatório'),
});

function FormRegister({ loading, user, onChange, primaryColor }) {
  const initialValues = {
    nome: user.name || '',
    cgc_cliente: user.cpf || '',
    password: '',
    confirmPassword: '',
  };
  const [newUser, setNewUser] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangeUser = async event => {
    setNewUser({ ...newUser, [event.target.id]: event.target.value });
  };

  const handleChangeRegister = async () => {
    await onChange(newUser.cgc_cliente, newUser.password);
  };

  const handleCancel = async () => {
    sessionStorage.removeItem('@portal-cliente/session');
    return <Redirect to="/" />;
  };

  return (
    <>
      <h4 className="font-size-30 mt-4">Recuperação de senha</h4>
      <p className="text-muted">
        Insira uma nova senha e confirme para completar a recuperação de senha
      </p>
      <div className="">
        <Formik
          initialValues={newUser}
          enableReinitialize={true}
          onSubmit={handleChangeRegister}
          validationSchema={schema}
        >
          {props => {
            const { values, touched, errors, handleBlur, handleSubmit } = props;
            return (
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="form-group auth-form-group-custom mb-4">
                  <label htmlFor="password">Nova Senha</label>
                  <input
                    id="password"
                    placeholder="Digite sua nova senha"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChangeUser}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? 'form-control text-input error'
                        : 'form-control text-input'
                    }
                  />
                  {showPassword ? (
                    <i
                      className="ri-eye-off-line auti-custom-input-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  ) : (
                    <i
                      className="ri-eye-line auti-custom-input-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  )}
                  {errors.password && touched.password && (
                    <div className="input-feedback error">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="form-group auth-form-group-custom mb-4">
                  <label htmlFor="confirmPassword">Confirmação de senha</label>
                  <input
                    id="confirmPassword"
                    placeholder="Confirme sua nova senha"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChangeUser}
                    onBlur={handleBlur}
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? 'form-control text-input error'
                        : 'form-control text-input'
                    }
                  />
                  {showConfirmPassword ? (
                    <i
                      className="ri-eye-off-line auti-custom-input-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    ></i>
                  ) : (
                    <i
                      className="ri-eye-line auti-custom-input-icon"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    ></i>
                  )}
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className="input-feedback error">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <a
                    href="/"
                    onClick={handleCancel}
                    style={{
                      padding: 13,
                      fontSize: 18,
                      fontWeight: 600,
                      float: 'left',
                      width: '45%',
                      paddingLeft: 0,
                    }}
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    Sair
                  </a>
                  <button
                    style={{
                      padding: 13,
                      fontSize: 18,
                      fontWeight: 600,
                      float: 'right',
                      width: '45%',
                      backgroundColor: primaryColor,
                      filter: loading ? 'opacity(30%)' : '',
                    }}
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Carregando...' : 'Salvar'}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default FormRegister;
