import React, { useEffect, useState, useContext } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import api from '../../services/api';
import Modal from 'react-modal';
import AuthContext from '../../contexts/auth';
import styled from "styled-components";

import { errorAlert } from '../../components/Toast';

import Table from './table';
import './style.css';
import 'semantic-ui-css/semantic.min.css';

function Dashboard(props) {
  const { user } = useContext(AuthContext);
  const group = JSON.parse(sessionStorage.getItem('@portal-cliente/group'));
  const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
  const [contracts, setContracts] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);



  const handleCheckout = () => {
    return props.history.push({
      pathname: '/checkout',
      state: {
        selected,
        cnpj: contracts[tab].cnpj
      },
    });
  };

  const handleGetCharges = async () => {
    try {
      const { data } = await api.get(`/charges/${group}/${user.cpf}/list`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      setContracts(data.contratos);
    } catch {
      errorAlert('Falha ao carregar titulos!');
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    handleGetCharges();
  }, []);

  useEffect(() => {
    // hack para atualizar os "check" das linhas da tabela - gambiarra
    setLoading(true)
    setTimeout(() => {setLoading(false)}, 1000)
  }, [tab]);

  return (
    <div
      className="tab-pane fade show active"
      id="vencimentos"
      role="tabpanel"
      aria-labelledby="vencimentos-tab"
    >
      <div className="container-fluid">
        <ul className="nav nav-tabs">
          {contracts &&
            contracts.map((item, index) => {
              return (
                <li className="nav-item" key={index} style={{ cursor: 'pointer' }}>
                  <a
                    className={index === tab ? 'nav-link active': 'nav-link'}
                    onClick={() => setTab(index)}
                  >
                    {item.tipo.toUpperCase() === "FUNERARIA"
                      ? `PLANO - ${item.codigo}`
                      : `${item.tipo.toUpperCase()} - ${item.codigo}`
                    }
                  </a>
                </li>
              );
            })}
        </ul>
        <Table
          tab={tab}
          loading={loading}
          contract={contracts ? contracts[tab] : null}
          onChange={handleCheckout}
          onSelected={setSelected}
        />
      </div>
    </div>
  );
}

export default Dashboard;
