import React, { createContext } from 'react';

const LayoutContext = createContext({
  loginBanner: String,
  primeryColor: String,
  secondaryColor: String,
  footerBanner: String,
  logoDefault: String,
  logDark: String,
  logoDarkIcon: String,
  logLight: String,
  logoLoghtIcon: String,
  logoCircle: String,
});

export const LayoutProvider = ({ children }) => {
  const layout = JSON.parse(localStorage.getItem('@portal-cliente/layout'));

  return (
    <LayoutContext.Provider
      value={layout}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;
