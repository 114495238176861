import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cgc_cliente: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Digite um email válido'),
  password: Yup.string()
    .min(6, 'A senha deve ter no minimo 6 caracteres')
    .required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('Campo obrigatório'),
  celular: Yup.string()
    .matches(/^[0-9()\- &]*$/, 'Número incompleto')
    .min(11, 'Número incompleto')
    .required('Campo obrigatório'),
  cep: Yup.string()
    .matches(/^[0-9\- &]*$/, 'CEP incompleto')
    .min(9, 'CEP incompleto'),
    // .required('Campo obrigatório'),
  endereco: Yup.string().required('Campo obrigatório'),
  complemento: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  municipio: Yup.string().required('Campo obrigatório'),
  estado: Yup.string()
    .notOneOf(['Selecione seu estado', null], 'Campo obrigatório')
    .required('Campo obrigatório'),
});

function FormRegister({ loading, user, primaryColor, onChange }) {
  const initialValues = {
    nome: user.name || '',
    cgc_cliente: user.cpf || '',
    email: user.email || '',
    password: '',
    confirmPassword: '',
    celular: user.cell || '',
    cep: user.zipcode || '',
    endereco: user.address || '',
    complemento: user.adjunct || '',
    bairro: user.neighborhood || '',
    municipio: user.city || '',
    estado: user.state || '',
  };
  const [newUser, setNewUser] = useState(initialValues);

  const handleChangeUser = async event => {
    setNewUser({...newUser, [event.target.id]: event.target.value})
  }

  const handleChangeRegister = async () => {
    onChange(newUser, 'firstaccess')
  }

  // useEffect(() => {
  //   const cep = newUser.cep.replace('-','').replace('_','');
  //   if(cep.length === 8) {
  //     Cep(cep)
  //       .then(resp => {
  //         setNewUser({
  //           ...newUser,
  //           estado: resp.state,
  //           bairro: resp.neighborhood.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
  //           municipio: resp.city.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
  //         })
  //       })
  //   }
  // }, [newUser.cep])

  return (
    <Formik
      initialValues={newUser}
      enableReinitialize={true}
      onSubmit={handleChangeRegister}
      validationSchema={schema}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                placeholder="Digite seu nome"
                type="text"
                value={values.nome}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                style={{
                  textTransform: 'uppercase'
                }}
                disabled
                className={
                  errors.nome && touched.nome
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.nome && touched.nome && (
                <div className="input-feedback error">{errors.nome}</div>
              )}
            </div>
            {/* <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="cgc_cliente">CPF</label>
              <InputMask
                id="cgc_cliente"
                placeholder="Digite o CPF"
                type="text"
                mask="999.999.999-99"
                value={values.cgc_cliente}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                className={
                  errors.cgc_cliente && touched.cgc_cliente
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
                disabled
              />
              {errors.cgc_cliente && touched.cgc_cliente && (
                <div className="input-feedback error">{errors.cgc_cliente}</div>
              )}
            </div>
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                placeholder="Digite seu email"
                type="text"
                value={values.email}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                style={{
                  textTransform: 'uppercase'
                }}
                className={
                  errors.email && touched.email
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.email && touched.email && (
                <div className="input-feedback error">{errors.email}</div>
              )}
            </div> */}
            {/* <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="password">Senha</label>
              <input
                id="password"
                placeholder="DIGITE SUA SENHA"
                type="password"
                value={values.password}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                className={
                  errors.password && touched.password
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.password && touched.password && (
                <div className="input-feedback error">{errors.password}</div>
              )}
            </div>
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="confirmPassword">Confirmação de senha</label>
              <input
                id="confirmPassword"
                placeholder="CONFIRME SUA SENHA"
                type="password"
                value={values.confirmPassword}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                className={
                  errors.confirmPassword && touched.confirmPassword
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <div className="input-feedback error">
                  {errors.confirmPassword}
                </div>
              )}
            </div> */}
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                placeholder="Digite o celular"
                type="text"
                mask="(99) 99999-9999"
                value={values.celular}
                onChange={handleChangeUser}
                onBlur={handleBlur}
                className={
                  errors.celular && touched.celular
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.celular && touched.celular && (
                <div className="input-feedback error">{errors.celular}</div>
              )}
            </div>
            {/* <div style={{ width: '100%', display: 'inline-flex' }}>
              <div
                style={{ width: '38%', marginRight: '5%' }}
                className="form-group auth-form-group-custom mb-4"
              >
                <label htmlFor="cep">CEP</label>
                <InputMask
                  type="phone"
                  id="cep"
                  value={values.cep || ''}
                  mask="99999-999"
                  className="form-control"
                  placeholder="Digite seu CEP"
                  onChange={handleChangeUser}
                  onBlur={handleBlur}
                  className={
                    errors.cep && touched.cep
                      ? 'form-control text-input error'
                      : 'form-control text-input'
                  }
                />
                {errors.cep && touched.cep && (
                  <div className="input-feedback error">{errors.cep}</div>
                )}
              </div>
              <div
                style={{
                  width: '58%',
                  float: 'right',
                }}
                className="form-group auth-form-group-custom mb-4"
              >
                <label htmlFor="endereco">Endereço</label>
                <input
                  type="text"
                  id="endereco"
                  value={values.endereco}
                  className="form-control"
                  placeholder="Digite seu endereco"
                  onChange={handleChangeUser}
                  onBlur={handleBlur}
                  style={{
                    textTransform: 'uppercase'
                  }}
                  className={
                    errors.endereco && touched.endereco
                      ? 'form-control text-input error'
                      : 'form-control text-input'
                  }
                />
                {errors.endereco && touched.endereco && (
                  <div className="input-feedback error">{errors.endereco}</div>
                )}
              </div>
            </div>
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="complemento">Complemento</label>
              <input
                type="text"
                id="complemento"
                value={values.complemento}
                className="form-control"
                placeholder="Digite o complemento"
                onChange={handleChangeUser}
                onBlur={handleBlur}
                style={{
                  textTransform: 'uppercase'
                }}
                className={
                  errors.complemento && touched.complemento
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.complemento && touched.complemento && (
                <div className="input-feedback error">{errors.complemento}</div>
              )}
            </div>
            <div className="form-group auth-form-group-custom mb-4">
              <label htmlFor="bairro">Bairro</label>
              <input
                type="text"
                id="bairro"
                value={values.bairro}
                className="form-control"
                placeholder="Digite o bairro"
                onChange={handleChangeUser}
                onBlur={handleBlur}
                style={{
                  textTransform: 'uppercase'
                }}
                className={
                  errors.bairro && touched.bairro
                    ? 'form-control text-input error'
                    : 'form-control text-input'
                }
              />
              {errors.bairro && touched.bairro && (
                <div className="input-feedback error">{errors.bairro}</div>
              )}
            </div>
            <div style={{ width: '100%', display: 'inline-flex' }}>
              <div
                style={{ width: '63%', marginRight: '5%' }}
                className="form-group auth-form-group-custom mb-4"
              >
                <label htmlFor="username">Cidade</label>
                <input
                  type="text"
                  id="municipio"
                  value={values.municipio}
                  className="form-control"
                  placeholder="Digite sua cidade"
                  onChange={handleChangeUser}
                  onBlur={handleBlur}
                  style={{
                    textTransform: 'uppercase'
                  }}
                  className={
                    errors.municipio && touched.municipio
                      ? 'form-control text-input error'
                      : 'form-control text-input'
                  }
                />
                {errors.municipio && touched.municipio && (
                  <div className="input-feedback error">{errors.municipio}</div>
                )}
              </div>
              <div
                style={{
                  width: '32%',
                  float: 'right',
                }}
                className="form-group auth-form-group-custom mb-4"
              >
                <label htmlFor="estado">Estado</label>
                <select
                  id="estado"
                  value={values.estado}
                  className="form-control form-select"
                  aria-label="Default select example"
                  onChange={handleChangeUser}
                  onBlur={handleBlur}
                  className={
                    errors.municipio && touched.municipio
                      ? 'form-control text-input error'
                      : 'form-control text-input'
                  }
                >
                  <option>Selecione o estado</option>
                  {UF.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div> */}

            <div className="mt-4 text-center">
              <a
                href="/"
                style={{
                  padding: 13,
                  fontSize: 18,
                  fontWeight: 600,
                  float: 'left',
                  width: '45%',
                  paddingLeft: 0,
                }}
                className="btn btn-outline-primary"
                onClick={() => sessionStorage.removeItem('@portal-cliente/user')}
              >
                Sair
              </a>
              <button
                style={{
                  padding: 13,
                  fontSize: 18,
                  fontWeight: 600,
                  float: 'right',
                  width: '45%',
                  backgroundColor: primaryColor,
                  filter: loading ? 'opacity(30%)' : '',
                }}
                className="btn btn-primary"
                type="submit"
                disabled={loading}
                onClick={handleChangeRegister}
              >
                {loading ? 'Carregando...' : 'Salvar'}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FormRegister;
