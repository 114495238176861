import React, { useState, useContext } from 'react';
import InputMask from 'react-input-mask';

import AuthContext from '../../../contexts/auth';
import LayoutContext from '../../../contexts/layout';

import { errorAlert } from '../../../components/Toast';

function SignIn() {
  const { signIn, loading } = useContext(AuthContext);
  const { logoDefault, primaryColor } = useContext(LayoutContext);

  const [login, setLogin] = useState({
    cgc_cliente: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async event => {
    event.preventDefault();

    if (login.password === '' || login.cgc_cliente === '') {
      return errorAlert(
        'CPF do Titular e Senha devem ser preenchidos corretamente',
      );
    }

    const cgc = login.cgc_cliente.replace(/[^0-9]+/g, '');

    // realiza o login
    await signIn({
      username: cgc,
      password: login.password,
    });
  };

  const handleChange = event => {
    setLogin({ ...login, [event.target.id]: event.target.value });
  };

  return (
    <div className="col-lg-7">
      <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div>
                <div>
                  <a href="/" className="logo">
                    <img src={logoDefault} height="80" alt="logo" />
                  </a>
                </div>
                <h4 className="font-size-30 mt-4">Portal do cliente</h4>
                <div className="">
                  <form className="form-horizontal" onSubmit={handleLogin}>
                    <div className="form-group auth-form-group-custom mb-4">
                      <label htmlFor="username">CPF do titular</label>
                      <InputMask
                        type="text"
                        name="cgc_cliente"
                        id="cgc_cliente"
                        mask="999.999.999-99"
                        onChange={handleChange}
                        className="form-control"
                        value={login.cgc_cliente}
                        placeholder="Digite o CPF do titular"
                      />
                    </div>
                    <div className="form-group auth-form-group-custom mb-4">
                      <label htmlFor="userpassword">Senha</label>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        id="password"
                        className="form-control"
                        placeholder="Digite sua senha"
                        value={login.password}
                        onChange={handleChange}
                      />
                      {showPassword
                        ? <i className="ri-eye-off-line auti-custom-input-icon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}></i>
                        : <i className="ri-eye-line auti-custom-input-icon" style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}></i>
                      }
                    </div>
                    <div className="custom-control custom-checkbox">
                      <div className="mt-4">
                        <a
                          href="recuperar-senha"
                          style={{
                            float: 'right',
                            marginTop: 0,
                            textDecoration: 'underline',
                            color: '#333333',
                            fontWeight: 600,
                          }}
                        >
                          Esqueceu a senha?
                        </a>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        style={{
                          minWidth: '45%',
                          padding: 13,
                          fontSize: 18,
                          fontWeight: 600,
                          backgroundColor: primaryColor,
                          filter: loading ? 'opacity(30%)' : '',
                        }}
                        className="btn btn-primary btn-lg btn-block mb-1"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Carregando...' : 'Entrar'}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="mt-4">
                  <p className="">
                    Novo usuário?{' '}
                    <a
                      href="primeiro-acesso"
                      className="font-weight-medium text-primary"
                    >
                      Cadastre-se
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
