import api from '../services/api';
import { errorAlert } from '../components/Toast';

// busca a bandeira do cartao
export async function getCardFlag(cardnumber) {
  cardnumber = cardnumber.replace(/[^0-9]+/g, '');

  const cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{14}$/,
    elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^504175|^627780|^63(6297|6368|6369)|(65003[5-9]|65004[0-9]|65005[01])|(65040[5-9]|6504[1-3][0-9])|(65048[5-9]|65049[0-9]|6505[0-2][0-9]|65053[0-8])|(65054[1-9]|6505[5-8][0-9]|65059[0-8])|(65070[0-9]|65071[0-8])|(65072[0-7])|(65090[1-9]|6509[1-6][0-9]|65097[0-8])|(65165[2-9]|6516[67][0-9])|(65500[0-9]|65501[0-9])|(65502[1-9]|6550[34][0-9]|65505[0-8])|^(506699|5067[0-6][0-9]|50677[0-8])|^(509[0-8][0-9]{2}|5099[0-8][0-9]|50999[0-9])|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])/,
    // diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    diners_club:
      /^(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
    // amex: /^3[47][0-9]{13}/,
    american_express: /^3[47][0-9]{13}/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    hipercard: /^606282|^637095|^637599|^637568/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    aura: /^507860/,
  };

  for (const flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return false;
}

// cadastra o usuario na vindi
export function newCustomer({
  name,
  street,
  number,
  zipcode,
  neighborhood,
  city,
  state,
  phone,
  phone_type,
  key_vindi,
  cpf,
  additional_details,
  token,
}) {
  // cria o usuario a ser cadastrado de acordo com o titular
  const newUser = {
    name,
    registry_code: cpf,
    code: `PORTAL-${Date.now()}_CGC:${cpf}`,
    notes: '',
    metadata: 'metadata',
    address: {
      street: street,
      number,
      additional_details,
      zipcode,
      neighborhood,
      city,
      state,
      country: 'BR',
    },
    phones: [
      {
        phone_type: phone_type,
        number: `55${phone}`,
        extension: '',
      },
    ],
  };

  return new Promise((resolve, reject) => {
    api
      .post(`/vindi/customer/create?api_key=${key_vindi}`, newUser, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        if (result.data.customer) {
          resolve(result.data.customer.id);
        } else {
          if (result.data.errors) {
            errorAlert(
              `Falha ao criar novo perfil de cliente. Tente novamente. Dados tecnicos: ${result.data.errors[0].id} - ${result.data.errors[0].parameter} - ${result.data.errors[0].message}`,
            );
            reject(result.data.errors);
          }
          reject();
        }
      })
      .catch(e => {
        errorAlert('Falha ao criar novo perfil de cliente. Tente novamente.');
        reject();
      });
  });
}

// cadastra o perfil de pagamento na vindi
export async function newPaymentProfile({
  holder_name,
  card_expiration,
  card_number,
  card_cvv,
  payment_method_code,
  customer_id,
  key_vindi,
  token,
}) {
  // detecta a bandeira do cartao
  const newType = await getCardFlag(card_number);

  // configura o perfil de pagamento
  const newProfile = {
    holder_name,
    card_expiration,
    card_number,
    card_cvv,
    payment_method_code,
    customer_id,
  };
  if (newType) {
    newProfile.payment_company_code = newType;
  }

  return new Promise((resolve, reject) => {
    api
      .post(`/vindi/payment/profile/create?api_key=${key_vindi}`, newProfile, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        if (result.data.payment_profile.id) {
          resolve(result.data);
        } else {
          if (result.data.errors) {
            errorAlert(
              `Falha ao criar novo perfil de pagamento, verifique os dados e tente novamente. Dados tecnicos: ${result.data.errors[0].id} - ${result.data.errors[0].parameter} - ${result.data.errors[0].message}`,
            );
            reject(result.data.errors);
          }
          reject();
        }
      })
      .catch(e => {
        errorAlert(
          'Falha ao criar novo perfil de pagamento, verifique os dados e tente novamente',
        );
        reject();
      });
  });
}

// transação de verificação
export async function transactionVerification(id) {
  return new Promise((resolve, reject) => {
    api
      .post(`/vindi/payment/profiles/${id}/verify?api_key=${key_vindi}`)
      .then(result => {
        if (result.data.status !== 'success') {
          resolve(true);
        }
        errorAlert(
          'Falha ao validar cartão de crédito. Cartão não aprovado para modelo de assinatura.',
        );
        reject();
      })
      .catch(() => {
        errorAlert(
          'Falha ao validar cartão de crédito. Cartão não aprovado para modelo de assinatura.',
        );
        reject();
      });
  });
}

// cadastra fatura na vindi
export async function newInvoice({
  customer_id,
  payment_method_code,
  installments,
  product_id,
  amount,
  key_vindi,
  token,
}) {
  // configura fatura
  const invoice = {
    customer_id,
    payment_method_code,
    installments,
    bill_items: [
      {
        product_id,
        amount,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    api
      .post(`/vindi/bills/create?api_key=${key_vindi}`, invoice, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        if (result.data.bill) {
          resolve(result.data.bill);
        } else {
          if (result.data.errors) {
            errorAlert(
              `Falha ao cadastrar fatura, verifique os dados e tente novamente. Dados tecnicos: ${result.data.errors[0].id} - ${result.data.errors[0].parameter} - ${result.data.errors[0].message}`,
            );
            reject(result.data.errors);
          }
          reject();
        }
      })
      .catch(() => {
        errorAlert('Falha ao cadastrar fatura');
        reject();
      });
  });
}

// cancela fatura na vindi
export function deleteInvoice(id, key_vindi, token) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/vindi/bills/${id}/delete?api_key=${key_vindi}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then(result => {
        if (result.data.errors) {
          reject();
        }
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}
