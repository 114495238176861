export default function (phone) {
  if (phone.length === 10) {
    return phone.replace(
      /(\d{2})(\d{4})(\d{4})/,
      function (regex, argumento1, argumento2, argumento3) {
        return '(' + argumento1 + ') ' + argumento2 + '-' + argumento3;
      },
    );
  }

  return phone.replace(
    /(\d{2})(\d{5})(\d{4})/,
    function (regex, argumento1, argumento2, argumento3) {
      return '(' + argumento1 + ') ' + argumento2 + '- ' + argumento3;
    },
  );
}
