import React, { useState, useEffect, useContext } from 'react';

import AuthContext from '../../contexts/auth';

import { errorAlert, successAlert } from '../../components/Toast';

import api from '../../services/api';

function Password() {
  const { loading, primaryColor, user } = useContext(AuthContext);
  const [pass, setPass] = useState({
    password: '',
    new_password: '',
    confirm_password: '',
  });
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = event => {
    setPass({ ...pass, [event.target.id]: event.target.value });
  };

  const handleSubmit = async () => {
    if (
      errorNewPassword ||
      errorConfirmPassword ||
      pass.password === '' ||
      pass.new_password === '' ||
      pass.confirm_password === ''
    ) {
      return errorAlert(
        'Existe erro no preenchimento do formulário, todos os campos devem ser preenchidos',
      );
    }

    try {
      const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
      const { status } = await api.put(
        '/customer/portal/pass',
        {
          username: user.cpf,
          password: pass.password,
          new_password: pass.new_password,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );

      if (status === 200) return successAlert('Senha atualizada com sucesso');
    } catch {
      return errorAlert('Falha ao tentar atualizar, tente novamente');
    }
  };

  useEffect(() => {
    if (pass.new_password.length > 0 && pass.new_password.length < 6)
      setErrorNewPassword(true);
    else setErrorNewPassword(false);

    if (
      pass.new_password !== pass.confirm_password &&
      pass.confirm_password.length > 0
    )
      setErrorConfirmPassword(true);
    else setErrorConfirmPassword(false);
  }, [pass.new_password, pass.confirm_password]);

  return (
    <div
      className="tab-pane fade show active"
      id="usuario"
      role="tabpanel"
      aria-labelledby="usuario-tab"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="my-1 p-3 bg-body rounded shadow-sm">
              <h6 className="titulo-card pb-2 mb-0">Dados do usuário</h6>
              <div
                className="alinhamento-formulario-nome-e-senha"
                style={{ paddingBottom: 50 }}
              >
                <div className="form-horizontal">
                  <div className="form-group auth-form-group-custom mb-4">
                    <label htmlFor="userpassword">Senha atual</label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      placeholder="Digite sua senha"
                      onChange={handleChange}
                    />
                    {showPassword ? (
                      <i
                        className="ri-eye-off-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    ) : (
                      <i
                        className="ri-eye-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      ></i>
                    )}
                  </div>
                  <div className="form-group auth-form-group-custom mb-4">
                    <label htmlFor="userpassword">Nova senha</label>
                    <input
                      type={showNewPassword ? 'text' : 'password'}
                      id="new_password"
                      placeholder="Digitar a nova senha"
                      onChange={handleChange}
                      className={
                        errorNewPassword
                          ? 'form-control text-input error'
                          : 'form-control text-input'
                      }
                    />
                    {showNewPassword ? (
                      <i
                        className="ri-eye-off-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      ></i>
                    ) : (
                      <i
                        className="ri-eye-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      ></i>
                    )}
                    {errorNewPassword && (
                      <div className="input-feedback error">
                        A senha deve ter no mínimo 6 caracteres
                      </div>
                    )}
                  </div>
                  <div className="form-group auth-form-group-custom mb-4">
                    <label htmlFor="userpassword">Confirmação de senha</label>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      id="confirm_password"
                      placeholder="Repitir a nova senha"
                      onChange={handleChange}
                      className={
                        errorConfirmPassword
                          ? 'form-control text-input error'
                          : 'form-control text-input'
                      }
                    />
                    {showConfirmPassword ? (
                      <i
                        className="ri-eye-off-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      ></i>
                    ) : (
                      <i
                        className="ri-eye-line auti-custom-input-icon"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      ></i>
                    )}
                    {errorConfirmPassword && (
                      <div className="input-feedback error">
                        As senhas nao correspondem
                      </div>
                    )}
                  </div>
                  <button
                    style={{
                      padding: 13,
                      fontSize: 18,
                      fontWeight: 600,
                      float: 'right',
                      width: '100%',
                      backgroundColor: primaryColor,
                      filter: loading ? 'opacity(30%)' : '',
                    }}
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {loading ? 'Carregando...' : 'Salvar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
