import React, { useContext, useEffect, useState, createRef } from 'react';
import { parse, format } from 'date-fns';
import Pdf from 'react-to-pdf';

import AuthContext from '../../contexts/auth';
import LayoutContext from '../../contexts/layout';

import cpfMask from '../../functions/cpfMask';
import cnpjMask from '../../functions/cnpjMask';
import phoneMask from '../../functions/phoneMask';
import toMoney from '../../functions/toMoney';

const ref = createRef();

function Detail(props) {
  const { user } = useContext(AuthContext);
  const { logoDarkIcon } = useContext(LayoutContext);
  const [payment] = useState(props.location.state.data || null);
  const [total, setTotal] = useState(0);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const values = [];
    [payment.payments].forEach(elem => {
      values.push(elem.valor_titulo);
      if (elem.vencimento)
        elem.vencimento = format(
          parse(elem.vencimento, 'yyyyMMdd', new Date()),
          'dd/MM/yyyy',
        );
    });
    const valueTotal = values.reduce((total, numero) => {
      return total + numero;
    }, 0);

    setTotal(toMoney(valueTotal));

    // pega os dados da empresa
    const companies = JSON.parse(
      localStorage.getItem('@portal-cliente/companies'),
    );
    const c = companies.find(elem => elem.cnpj === payment.cnpj);
    setCompany(c);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex justify-content-center col-md-12">
          <div className="card-comprovante my-1 p-3 bg-body rounded shadow-sm">
            <div style={{ padding: 10 }} ref={ref}>
              <div className="d-flex flex-row">
                <div className="d-flex justify-content-start">
                  <div className="align-self-center"></div>
                  <div style={{ padding: 0 }} className="align-self-center">
                    <p
                      style={{ fontSize: 18, marginBottom: 0, fontWeight: 700 }}
                    >
                      RECIBO DE PAGAMENTO
                    </p>
                    <p
                      style={{
                        marginBottom: 5,
                        fontSize: 12,
                        fontWeight: 600,
                        marginTop: 15,
                      }}
                    >
                      Dados da empresa
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 0,
                        color: '#767676',
                      }}
                    >
                      CNPJ:
                      <span style={{ paddingLeft: 15 }}>
                        {/* XX. XXX. XXX/0001-XX */}
                        {cnpjMask(payment.cnpj)}
                      </span>
                    </p>
                    {company &&
                      company.address.map((item, index) => (
                        <p
                          style={{
                            fontSize: 12,
                            marginBottom: 0,
                            color: '#767676',
                          }}
                          key={index}
                        >
                          <span>{item}</span> <br />
                        </p>
                      ))}
                    <p
                      style={{
                        fontSize: 12,
                        marginBottom: 0,
                        color: '#767676',
                      }}
                    >
                      Telefone:
                      <span style={{ paddingLeft: 15 }}>
                        {company && phoneMask(company.phone)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: -15,
                  marginRight: -15,
                }}
                className="borda-padrao flex-row"
              ></div>
              <div style={{ marginTop: 20 }} className="d-flex flex-row">
                <div className="align-self-center">
                  <p
                    style={{ fontSize: 15, marginBottom: 0, color: '#767676' }}
                  >
                    Nome:
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#333333',
                        paddingLeft: 15,
                      }}
                    >
                      {user.name}
                    </span>
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      color: '#767676',
                      marginTop: 10,
                    }}
                  >
                    CPF:
                    <span
                      style={{
                        fontWeight: 600,
                        color: '#333333',
                        paddingLeft: 31,
                      }}
                    >
                      {cpfMask(user.cpf)}
                    </span>
                  </p>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: -15,
                  marginRight: -15,
                }}
                className="borda-padrao flex-row"
              ></div>
              <div className="borda-padrao d-flex justify-content-between">
                <div className="align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li className="">Data</li>
                    <li className="">Cartão</li>
                    <li className="">NSU</li>
                  </ul>
                </div>
                <div className="soma-valor-pagamento align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li className="lista-comprovante-2 text-end">
                      {`${payment.date} às ${payment.hour}`}
                    </li>
                    <li className="lista-comprovante-2 text-end">
                      {payment.card.toUpperCase()}
                    </li>
                    <li
                      style={{ fontSize: 12 }}
                      className="lista-comprovante-2 text-end"
                    >
                      {payment.nsu}
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  fontWeight: 600,
                  fontSize: 12,
                }}
                className="d-flex justify-content-center"
              >
                Títulos recebidos
              </div>
              <div className="borda-padrao d-flex justify-content-between">
                <div className="align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li className="">Vencimento</li>
                    <li className="">{payment.payments.chave.trim()}</li>
                  </ul>
                </div>
                <div className="soma-valor-pagamento align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li className="lista-comprovante-2 text-end">
                      {payment.payments.vencimento}
                    </li>
                    <li className="lista-comprovante-2 text-end">
                      R$ {toMoney(payment.payments.valor_titulo)}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="borda-padrao d-flex justify-content-between">
                <div className="align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li
                      style={{
                        fontWight: 600,
                        color: '#333333',
                        fontSize: 18,
                        marginTop: 20,
                      }}
                      className=""
                    >
                      Total
                    </li>
                  </ul>
                </div>
                <div className="soma-valor-pagamento align-self-center bd-highlight">
                  <ul className="lista-comprovante">
                    <li
                      style={{
                        fontWeight: 600,
                        color: '#333333',
                        fontSize: 18,
                        marginTop: 20,
                      }}
                      className="lista-comprovante-2 text-end"
                    >
                      R$ {total}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: 20, textAlign: 'center' }}
              className="mx-auto"
            >
              <Pdf targetRef={ref} filename="comprovante.pdf">
                {({ toPdf }) => (
                  <button className="btn btn-primary" onClick={toPdf}>
                    Imprimir comprovante
                  </button>
                )}
              </Pdf>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detail;
